















































































import { Aluno, Usuario } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { UsuarioService } from "@/core/services/geral/UsuarioService";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ListaInstrutor extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        sortBy:['complementar.nome'],
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Foto', value: 'foto', sortable: false, type:'bool' },
        { text: 'Login', value: 'login' },
        { text: 'E-mail', value: 'complementar.email' },
        { text: 'Nome', value: 'complementar.nome' },
        { text: 'Perfil', value: 'perfil.nome' },
        { text: 'Ativo', value: 'ativo', type:'bool' },
    ];

    item = new Usuario();
    service = new UsuarioService();
    
    foto: any = null;

    aluno = new Aluno();
    dialogCadastroAluno: boolean = false;

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, '', 'Perfil,Endereco.Municipio.Estado').then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => {
            if (!err.response){
                if(!navigator.onLine){
                    this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                }
                else{
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
            }
            else{
                this.$swal("Aviso", err.response.data, "error");
            }
        })
        .finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item?: Usuario){
        if(item){
            this.service.ObterPorId(item.id,"Equipe,Endereco.Municipio.Estado").then(
                res=>{
                    this.item = res.data;
                    this.dialogCadastro = true;
                    
                },
                err => {
                    if (!err.response){
                        if(!navigator.onLine){
                            this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                        }
                        else{
                            this.$swal("Aviso", "Não foi possível acessar a API", "error");
                        }
                    }
                    else{
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            )
        }
        else{
            this.item = new Usuario();
            this.dialogCadastro = true;
        }
    }

    ResetarSenha(item: Usuario){
        this.$swal({    
            title: 'Atenção!',
            html:  `<div>Tem certeza que deseja resetar a senha do usuário: <br/> ${item.complementar.nome} ?<div/>`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.service.ResetarSenha(item.id).then(
                    res => {
                        this.$swal("Aviso", res.data, res.status == 201 || res.status == 200 ? "success" : "warning");
                    },
                    err => {
                        if (!err.response){
                            if(!navigator.onLine){
                                this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                            }
                            else{
                                this.$swal("Aviso", "Não foi possível acessar a API", "error");
                            }
                        }
                        else{
                            this.$swal("Aviso", err.response.data, "error");
                        }
                    }
                )
            },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value){
                this.$swal("Aviso", result.value, "success");
                this.Atualizar();
            }
        })
    }

    Excluir(item: Usuario){
        this.$swal({    
            title: 'Atenção!',
            text: 'Tem certeza que deseja excluir o registro atual?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.service.Excluir(item.id)
                .then(
                    res => {
                        if (res.status == 200){
                            return res.data;
                        }
                    },
                    err => {
                        if (err.response.status == 403){
                            this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                        }
                        else{
                            this.$swal('Aviso', err.response.data, 'error')
                        }
                    }
                )
                },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value){
                this.$swal("Aviso", result.value, "success");
                this.Atualizar();
            }
        })
    }    

    AbrirDialogCadastroAluno(item: Usuario){
        this.aluno = new Aluno();
        this.aluno.nomeCracha = item.nomeCracha;
        this.aluno.usuario = item;
        this.aluno.cpf = item.cpf;
        this.aluno.nascimento = item.nascimento;
        this.aluno.nome = item.complementar.nome;
        this.aluno.endereco.logradouro = item.endereco.logradouro;
        this.aluno.endereco.numero = item.endereco.numero;
        this.aluno.endereco.complemento = item.endereco.complemento;
        this.aluno.endereco.cep = item.endereco.cep;
        this.aluno.endereco.bairro = item.endereco.bairro;
        this.aluno.endereco.municipioId = item.endereco.municipioId;
        this.aluno.telefone = item.complementar.telefone;
        this.aluno.email = item.complementar.email;
        this.aluno.celular = item.complementar.celular;
        this.aluno.celularWhatsapp = item.complementar.celular;

        this.dialogCadastroAluno = true;
    }
}
